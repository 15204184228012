import ellipsize from "ellipsize";
import BigNumber from "bignumber.js";
import Color from "colorjs.io";
import dayjs from "@/utils/dayjsConfig";
import type { BlogData, INewsResponse } from "@/models/news";
import { IMAGE_SHARE_CDN } from "@/constant/apiPath";
import imageBlog from "@/asset/images/image-blog.png";
import imageExample from "@/asset/images/image-example.png";
import { DateTimeFormat } from "@/constant";
import useColorChange from "use-color-change";
import type { CSSProperties } from "react";

export interface CurrencyIF {
  code: string; // USD
  name: string; // US Dollar
  symbol: string; // $
  rate: number; // 1
  icon?: string;
}
export const NewsFromProjectCategory = "Project Blog";
export const ellipsisString = (input: string | undefined, length: number) =>
  ellipsize(input, length, { chars: [" ", ",", ".", "-"] });
export const formatPercent = (n: number | undefined) => formatThousands(n, "", true);
export const formatThousands = (
  n: number | undefined | null,
  prefixSetting: string | CurrencyIF = "",
  max2Digits = false,
  isShowChange?: boolean,
): string => {
  const prefix: string = typeof prefixSetting === "string" ? prefixSetting : prefixSetting.symbol;
  const prefixText = prefix.startsWith(" ") ? "" : prefix;
  const suffixText = !prefix.startsWith(" ") ? "" : prefix;

  if (n !== undefined && n !== null) {
    let num = BigNumber(n).multipliedBy((prefixSetting as CurrencyIF).rate ?? 1);

    if (!num.isFinite()) {
      if (num.isNaN()) {
        return "NaN";
      }
      return "∞";
    }

    if (max2Digits) {
      num = num.decimalPlaces(2);
    }
    if (n) {
      switch (true) {
        case n > 10000 && n < 1000000:
          num = num.decimalPlaces(0);
          break;
        case n > 1000 && n <= 10000:
          num = num.decimalPlaces(1);
          break;
        case n > 10 && n <= 1000:
          num = num.decimalPlaces(2);
          break;
        case n >= 1 && n <= 10:
          num = num.decimalPlaces(3);
          break;
      }
    }

    if (num.isZero()) {
      // x == 0
      // Or max 2 digits setting
      return `${prefixText}0${suffixText}`;
    } else if (num.abs().isLessThan(1)) {
      // x < 1
      if (num.abs().isLessThan(0.00001)) {
        // x < 0.00001
        let i = 1;
        while (num.abs().multipliedBy(BigNumber(10).pow(i++)).isLessThan(1)) {
          /* empty */
        }
        i--;
        num = num.multipliedBy(BigNumber(10).pow(i));

        return (
          removeZeroTail(
            `${num.isLessThan(0) ? "-" : isShowChange ? "+" : ""}${prefixText}${num.abs().decimalPlaces(3)}`,
          ) + `e-${i}${suffixText}`
        );
      } else {
        // 1 > x >= 0.00001
        return removeZeroTail(
          `${num.isLessThan(0) ? "-" : isShowChange ? "+" : ""}${prefixText}${num.abs().toPrecision(4)}${suffixText}`,
        );
      }
    } else {
      // x >= 1
      if (num.abs().lt(1000000)) {
        // x < 1M
        let i = 0;
        const expectedLength = 8 - prefix.length - (num.isNegative() ? 1 : 0);
        while (num.abs().toFormat(i++).replaceAll(/[.,]/g, "").length < expectedLength) {
          if (i > 2) {
            break;
          }
        }
        i--;

        return removeZeroTail(
          `${num.isLessThan(0) ? "-" : isShowChange ? "+" : ""}${prefixText}${num.abs().toFormat(i)}${suffixText}`,
        );
      } else {
        // x >= 1M
        const [div, sub] = findMollifyOption(num);

        let i = 0;
        const expectedLength = 8 - prefix.length - sub.length - (num.isNegative() ? 1 : 0);
        while (num.abs().div(div).toFormat(i++).replaceAll(/[.,]/g, "").length < expectedLength) {
          if (i > 3) {
            break;
          }
        }
        i--;
        return (
          removeZeroTail(
            `${num.isLessThan(0) ? "-" : isShowChange ? "+" : ""}${prefixText}${num.abs().div(div).toFormat(i)}`,
          ) +
          sub +
          suffixText
        );
      }
    }
  }
  return "--";
};
const removeZeroTail = (input: string) => input.replace(/\.?0*$/, "");
const findMollifyOption = (n: any): [BigNumber, string] => {
  const number = new BigNumber(n).abs();
  if (number.gte(Math.pow(10, 12))) {
    const opt = findMollifyOption(number.div(Math.pow(10, 12)));
    return [opt[0].multipliedBy(Math.pow(10, 12)), opt[1] + "T"];
  } else if (number.gte(Math.pow(10, 9))) {
    return [new BigNumber(Math.pow(10, 9)), "B"];
  } else if (number.gte(Math.pow(10, 6))) {
    return [new BigNumber(Math.pow(10, 6)), "M"];
  } else if (number.gte(Math.pow(10, 3))) {
    return [new BigNumber(Math.pow(10, 3)), "K"];
  }

  return [new BigNumber(1), ""];
};
function calculatePercent(min: number, max: number, value: number) {
  return (value - min) / (max - min);
}
export const mapColorHeatmap = (value: number, max: number) => {
  switch (true) {
    case value <= -max:
      return new Color("#f02a4559");
    case value > -max && value <= (-2 * max) / 3:
      return new Color("#f02a4559").range(new Color("#f02a4533"))(calculatePercent(-max, (-2 * max) / 3, value));
    case value > (-2 * max) / 3 && value <= -max / 3:
      return new Color("#f02a4533").range(new Color("#f02a451a"))(calculatePercent((-2 * max) / 3, -max / 3, value));
    case value > -max / 3 && value <= 0:
      return new Color("#f02a451a").range(new Color("#4145541a"))(calculatePercent(-max / 3, 0, value));
    case value > 0 && value <= max / 3:
      return new Color("#4145541a").range(new Color("#229f6c1a"))(calculatePercent(0, max / 3, value));
    case value > max / 3 && value <= (2 * max) / 3:
      return new Color("#229f6c1a").range(new Color("#229f6c33"))(calculatePercent(max / 3, (2 * max) / 3, value));
    case value > (2 * max) / 3 && value <= max:
      return new Color("#229f6c33").range(new Color("#229f6c59"))(calculatePercent((2 * max) / 3, max, value));
    case value > max:
      return new Color("#229f6c59");
    default:
      return new Color("#f73639");
  }
};
export const arrayReference: { color: string; value: { [key: string]: number } }[] = [
  {
    color: "rgba(240, 42, 69, 0.35)",
    value: {
      "1D": -3,
      "1W": -6,
      "1M": -15,
      "3M": -30,
      "6M": -45,
      "1Y": -60,
      YTD: -60,
    },
  },
  {
    color: "rgba(240, 42, 69, 0.2)",
    value: {
      "1D": -2,
      "1W": -4,
      "1M": -10,
      "3M": -20,
      "6M": -30,
      "1Y": -40,
      YTD: -40,
    },
  },
  {
    color: "rgba(240, 42, 69, 0.1)",
    value: {
      "1D": -1,
      "1W": -2,
      "1M": -5,
      "3M": -10,
      "6M": -15,
      "1Y": -20,
      YTD: -20,
    },
  },
  {
    color: "rgba(65, 69, 84, 0.1)",
    value: {
      "1D": 0,
      "1W": 0,
      "1M": 0,
      "3M": 0,
      "6M": 0,
      "1Y": 0,
      YTD: 0,
    },
  },
  {
    color: "rgba(34, 159, 108, 0.1)",
    value: {
      "1D": 1,
      "1W": 2,
      "1M": 5,
      "3M": 10,
      "6M": 15,
      "1Y": 20,
      YTD: 20,
    },
  },
  {
    color: "rgba(34, 159, 108, 0.2)",
    value: {
      "1D": 2,
      "1W": 4,
      "1M": 10,
      "3M": 20,
      "6M": 30,
      "1Y": 40,
      YTD: 40,
    },
  },
  {
    color: "rgba(34, 159, 108, 0.35)",
    value: {
      "1D": 3,
      "1W": 6,
      "1M": 15,
      "3M": 30,
      "6M": 45,
      "1Y": 60,
      YTD: 60,
    },
  },
];
export const sanitizeHtmlContent = (html: string): string =>
  html
    .replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, "")
    .replace(/<style[^>]*>([\s\S]*?)<\/style>/gi, "")
    .replace(/on\w+="[^"]*"/g, "")
    .replace(/javascript:/gi, "");

export function isExpired(expiredTime: number) {
  return !dayjs(expiredTime * 1000)
    .subtract(5, "minutes")
    .isAfter(dayjs());
}

export const addressShorter = (address: string | undefined, width = 4) =>
  address ? `${address.slice(0, width)}...${address.slice(address.length - width)}` : "--";
export const processNewsData = (news: INewsResponse) => {
  const title = sanitizeHtmlContent(news.title || news.description || "");
  const description = sanitizeHtmlContent(news.description || news.content || "");

  const imageUrl = news?.is_klarda
    ? `${IMAGE_SHARE_CDN}${news?.thumbnail?.replace(/ /g, "%20")}`
    : news.thumbnail?.replace(/ /g, "%20") ||
    (news.klarda_categories?.includes(NewsFromProjectCategory) ? imageBlog : imageExample);

  return { description, title, imageUrl };
};
export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
export const isPublishedBlog = (data: BlogData) => {
  const { scheduleTime, isPublishedNow } = data;
  if (isPublishedNow) {
    return true;
  } else {
    const currentTimeUTC = new Date();
    return currentTimeUTC > new Date(scheduleTime);
  }
};
export const getPublishedTime = (data?: BlogData) => {
  if (data?.isPublishedNow === true) {
    return dayjs(data?.created_at).format(DateTimeFormat);
  } else {
    return dayjs(data?.scheduleTime).format(DateTimeFormat);
  }
};

export function useNumberColorChange(value: number) {
  return useColorChange(value, {
    higher: "#52c41a",
    lower: "#ff4d4f",
    duration: 3000,
  });
}
export const isRoundNegative = (n: number | undefined, decimals = 2) =>
  Math.round((n || 0) * Math.pow(10, decimals)) < 0;

export const isRoundPositive = (n: number | undefined, decimals = 2) =>
  Math.round((n || 0) * Math.pow(10, decimals)) > 0;
export const capitalizeEveryFirstCharacter = (characters: string) => {
  if (!characters) return;

  const charactersArr = characters?.split(" ");

  for (let i = 0; i < charactersArr?.length; i++) {
    charactersArr[i] = charactersArr[i][0]?.toUpperCase() + charactersArr[i].substring(1);
  }

  return charactersArr?.join(" ");
};
export const compareNumber = (n1: number, n2: number, isDesc = false): number =>
  (isDesc ? -1 : 1) * (n1 > n2 ? 1 : n1 < n2 ? -1 : 0);
export const measureTextWidth = (text: string, opts?: CSSProperties) => {
  const textEl = document.getElementById("text_measure");
  if (textEl) {
    textEl.innerHTML = text;
    if (opts) {
      for (const optsKey in opts) {
        (textEl.style as any)[optsKey] = (opts as any)[optsKey].toString();
      }
    }

    return textEl.clientWidth;
  }

  return 0;
};
export const customUrl = (url: string) => {
  let result = "";
  if (url) {
    const myUrl = new URL(url);
    result = myUrl["hostname"];
    result = (result || "").toLowerCase();
  }
  return result;
};
export const isUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const convertExpiredTime = (timeInSeconds = 0) => {
  return new Date().getTime() + timeInSeconds * 1000;
};
